.main {
	margin:auto;
	text-align: center;
}

.main .value-input {
	line-height: 50px;
	font-size: 20px;
}

.main .settings {
	border-top: 2px solid rgb(152, 152, 152);
	padding: 20px;
}

.main .settings .value-input {
	width: 90%;
}

.main .application .value-input {
	width: 784px;
	height: 60px;
	border-radius: 17px;
	border: 3px solid #FFFFFF;
	padding: 0 20px;
}

.main .btn {
	margin: auto;
}

.main .btn-group .btn {
	padding-left: 7rem;
	padding-right: 7rem;
}

.main .input-group {
	margin: auto;
	position: relative;
	width: fit-content;
	margin-bottom: 20px;
}

.input-group .btn {
	position: absolute;
	top: 11px;
	right: 10px;
	/* background: linear-gradient(360deg, #4D7FFF 0%, #3CBDFF 89.17%); */
	border-radius: 10px;
	border: none;
	padding: 10px 35px;
	font-size: 20px;
}

.input-group .icon {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 20px;
	left: 20px;
}
.application .input-group .value-input {
	padding-left: 60px;
}
.application .input-group .value-input::placeholder {
	opacity: 0.3;
}

.section.application .validation-error {
	color: #FFBD3D;
}
.section.application .validation-error .icon,
.section.application .whitelist-result .icon {
	width: 11px;
	height: 11px;
	display: inline-block;
	margin-right: 8px;
}

.hero-title {
	font-weight: 600;
	font-size: 130px;
	color: #FFFFFF;
	margin: 0 0;
	line-height: 120px;
}

.hero-sub-title {
	color: #C8FFFF;
	font-size: 60px;
	margin: 0.1rem;
}

.bullet-points {
	margin: 45px 0;
	White-space: nowrap;
	display: inline-flex;
	gap: 30px;
}

.point-item {
	font-size: 18px;
	font-weight: 600;
}

.check-mark {
	background-image: url('../../../public/check.png');
	width: 20px;
	height: 20px;
	background-size: contain;
	display: inline-block;
	vertical-align: bottom;
	margin-right: 8px;
}

.basename-select-item {
    font-size: 20px;
    line-height: 20px;
    width: 840px;
    height: 66px;
    padding: 0 10px 0 20px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 20px 40px rgb(26 146 255 / 30%);
    border-radius: 17px;
	color: rgba(251, 251, 251, 0.5);
	cursor: pointer;
}
.basename-select-item:hover {
	background: rgba(255, 255, 255, 0.3);
}

.basename-select-item .icon {
	width: 20px;
	height: 20px;
	margin-right: 20px;
  margin-left: 3px;
}

.basename-select-item .icon-selection {
	background-image: url('../../../public/warning.png');
}
.basename-select-item.available {
	color: #fff;
}
.basename-select-item.available .icon-selection {
	background-image: url('../../../public/plus-icon.png');
}
.basename-select-item.selected {
	background: rgba(255, 255, 255, 0.3);
    border: 3px solid #FFFFFF;
    padding: 0 7px 0 17px;
    height: 60px;
}
.basename-select-item.selected .basename-select-item-left {
	padding-top: 19px;
}
.basename-select-item.selected .basename-select-item-right .btn-secondary {
	border-color: rgba(255, 255, 255, 0.3);
}
.basename-select-item.available.selected .icon-selection {
	background-image: url('../../../public/check-green.png');
}

.basename-select-item-left {
	padding-top: 22px;
}

.basename-select-item span {
	display: inline-block;
	vertical-align: top;
}

.basename-select-item-right {
	display: flex;
}

.basename-select-item-right .availability {
	padding: 22px;
}
.basename-select-item-right .btn {
	width: 127px;
    padding: 10px 0;
    font-size: 15px;
    border-radius: 10px;
}

.btn.btn-secondary.whitelist {
	margin: auto;
    font-size: 20px;
    padding: 1rem 5rem;
}

.emoji {
	width: 23px;
    height: 23px;
    display: inline-block;
    background-size: contain;
    margin-right: 15px;
    vertical-align: bottom;
}

.emoji-gift { background-image: url('../../../public/emoji-gift.png'); }
.emoji-unhappy { background-image: url('../../../public/emoji-unhappy.png'); }

@media (max-width: 980px) {
	.hero-title {
		font-size: 90px;
		line-height: 80px;
	}

	.hero-sub-title {
		font-size: 40px;
	}

	.bullet-points {
		margin: 30px 0;
		gap: 18px;
	}

	.main .application .value-input {
		width: 480px;
	}
}

@media (max-width: 600px) {
	.main {
		padding: 0px 30px;
	}

	.main .application .value-input {
		width: 320px;
		height: 65px;
		border-radius: 17px;
		border: 3px solid #FFFFFF;
		padding-left: 50px;
	}

	.input-group .btn {
		position: absolute;
		top: 13px;
		border-radius: 10px;
		border: none;
		padding: 7px 35px;
		font-size: 20px;
	}

	.hero-title {
		font-size: 42px;
		line-height: 35px;
	}

	.hero-sub-title {
		font-size: 20px;
	}

	.check-mark {
		width: 12px;
		height: 12px;
		margin-right: 3px;
	}

	.bullet-points {
		margin: 20px 0;
		gap: 12px;
	}

	.point-item {
		font-size: 8px;
	}
}

@media (max-width: 480px) {
	.main .application .value-input {
		width: 280px;
	}
}

@media (max-width: 360px) {
	.main .application .value-input {
		width: 240px;
	}
}
