.app {
	background: url('../public/bg.png') no-repeat center center fixed; 
  	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
	min-height: 100vh;
	overflow-x: hidden;
	color: #FFFFFF;
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 0;
}

.content {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

a {
	text-decoration: none;
}

.btn {
	width: fit-content;
	padding: 1rem 2rem;
	border-radius: 17px;
	/* margin: 5px; */
	font-weight: 500;
	font-size: 25px;
}

.btn-primary {
	background: #535AF7;
	color: #FFFFFF;
	/* border: 3px solid #FFFFFF; */
	border-radius: 17px;
}

.btn-primary:hover {
	background: #3A41D0;
}

.btn-secondary {
	color: #FFFFFF;
	border: 3px solid #FFFFFF;
	background-color: transparent;
}
.btn-secondary:hover {
	background: rgba(255, 255, 255, 0.2)
}

.btn {
	cursor: pointer;
}
.btn.disabled {
	cursor: not-allowed;
	filter: opacity(0.5);
	background-color: #8C8DAD;
}

.btn-group {
	display: flex;
	margin: auto;
}

.icon {
	background-size: contain;
}

.nowrap {
	white-space: nowrap;
}

.icon-magnifier { background-image: url('../public/search-icon.png') }
.icon-checked { background-image: url('../public/check-green.png') }
.icon-close { background-image: url('../public/close.png') }
.icon-person { background-image: url('../public/person-icon.png') }
.icon-arrow { background-image: url('../public/arrow-icon.png') }
.icon-loading { background-image: url('../public/loading.gif') }
.icon-discord { background-image: url('../public/discord-icon.png') }
.icon-twitter { background-image: url('../public/twitter-icon.png') }
.icon-warning { background-image: url('../public/warning.png') }
.icon-logout { background-image: url('../public/logout.png') }
.icon-nswap { background-image: url('../public/nswap-logo.png') }
.icon-linktree { background-image: url('../public/linktree-icon.png') }

.nft-ethw { background-color: rgba(31, 140, 248, 0.5) }
.nft-awsb { background-color: rgba(80, 107, 233, 0.5) }
.nft-wens { background-color: rgba(67, 66, 218, 0.5) }
.nft-pow { background-color: rgba(0, 154, 189, 0.5) }
.nft-ape { background-color: rgba(69, 51, 156, 0.5) }
.nft-mystery { background-color: linear-gradient(90deg, rgba(151, 248, 255, 0.6) 0%, rgba(146, 192, 255, 0.6) 40.9%, rgba(218, 167, 252, 0.6) 71.08%, rgba(255, 195, 229, 0.6) 100%) }

.nft-image.ethw { background-image: url('../public/nft-ethw.png') }
.nft-image.awsb { background-image: url('../public/nft-awsb.png') }
.nft-image.ape { background-image: url('../public/nft-ape.png') }
.nft-image.pow { background-image: url('../public/nft-pow.png') }
.nft-image.wens { background-image: url('../public/nft-wens.png') }
.nft-image.blank { background-image: url('../public/nft-blank.png') }

@media (max-width: 600px) {
	.btn {
		font-size: 15px;
	}
}
