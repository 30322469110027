.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 60px;
    flex: 0;
}

.header .logo {
    float: left;
    width: 53px;
    height: 53px;
    background-image: url('../../../public/logo.png');
    background-size: contain;
}

.header .icon-mysterybox {
    width: 40px;
    height: 42px;
    background-image: url('../../../public/mystery-icon.png');
    background-size: contain;
    position: relative;
}
.header .icon-mysterybox .notification-dot {
    background-color: #fb6a6a;
    position: absolute;
    right: -7px;
    font-size: 12px;
    line-height: 15px;
    padding: 0 6px;
    border-radius: 40%;
    display: inline-block;
}

.header .icon-account {
    width: 42px;
    height: 42px;
    background-image: url('../../../public/account-icon.png');
    background-size: contain;
}

.header .icon-logout {
    width: 42px;
    height: 42px;
    background-image: url('../../../public/logout-icon.png');
    background-size: contain;
}

.header .title {
    float: left;
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    color: #fff;
    margin-left: 6px;
}

.header .btn-secondary.no-border {
    border: none;
    padding: 0.4rem;
    border-radius: 50%;
}

.header-buttons {
    display: inline-flex;
    gap: 40px;
}

.header-buttons button {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
}
.header-buttons .nav-icon {
    vertical-align: bottom;
}

.header .my-account-card {
    position: absolute;
    right: 55px;
    top: 40px;
}

@media (max-width: 980px) {
    .header {
        padding: 36px 36px;
    }

    .header-buttons {
        gap: 30px;
    }

    .header .my-account-card {
        /* width: 200px;
        height: 100px; */
        right: 40px;
        top: 35px;
        /* padding-left: 15px;
        gap: 15px; */
    }

    /* .header .my-account-card .icon-person {
        width: 45px;
        height: 45px;
    }

    .my-account-card .nft-details .nft-details-heading {
        margin: 5px 0;
    } */
}

@media (max-width: 600px) {
    .header {
        padding: 24px 24px;
    }

    .header-title {
        display: inline-flex;
        align-items: center;
    }

    .header .logo {
        width: 36px;
        height: 36px;
    }

    .header .icon-mysterybox {
        width: 26px;
        height: 28px;
    }

    .header .icon-account {
        width: 28px;
        height: 28px;
    }

    .header .icon-logout {
        width: 28px;
        height: 28px;
    }

    .header .title {
        font-size: 20px;
        line-height: 28px;
    }

    .header .btn-secondary.no-border {
        padding: 0;
    }

    .header-buttons {
        gap: 20px;
    }

    .header-buttons .btn {
        font-size: 12px;
        border-radius: 10px;
        border: 2px solid #FFFFFF;
        padding: 5px 24px;
    }

    .header-buttons .btn span {
        display: none;
    }

    .header .my-account-card {
        right: 20px;
        top: 20px;
    }
}
