.footer {
    margin: auto;
    font-size: 18px;
    font-weight: 400;
    flex: 0;
}

.footer .social-icons {
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
}

.footer .social-icons .icon {
    height: 20px;
    width: 20px;
    opacity: 0.5;
}
.footer .social-icons .icon.icon-linktree {
    width: 16px;
}
.footer .social-icons .icon:hover {
    opacity: 1;
}

.footer p {
    opacity: 0.5;
}

@media (max-width: 980px) {
    .footer {
        height: 30px;
        font-size: 14px;
    }

    .footer .social-icons {
        gap: 20px;
    }

    .footer .social-icons .icon {
        height: 16px;
        width: 16px;
    }
}

@media (max-width: 600px) {
    .footer {
        height: 20px;
        font-size: 10px;
    }

    .footer .social-icons {
        gap: 10px;
        margin-top: 15px;
    }

    .footer .social-icons .icon {
        height: 12px;
        width: 12px;
    }
}
